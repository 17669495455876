import './_redirects';
import './robots.txt';
import './globals.scss';
import 'react-virtualized/styles.css';
import 'babel-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { Provider as ReduxQueryProvider } from 'redux-query-react';

import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { listenForHistoryChange } from 'redux-location-state';
import { getQueries } from './reducers';
import store from './store';
import ErrorBoundary from 'Components/ErrorBoundary';
import Routing, { history } from './navigation/Routing';

import { soomoMuiTheme, soomoTheme } from 'Theme/theme';

// Answers built here might not have IDs.
// https://github.com/soomo/soomo/blob/staging/app/services/answering/multiple_choice_answering_service.rb
// This is the result of the mobile app coming online and syncing with later answers.
// Therefore, when we consume answer objects in notebook, we use this decrementing syntheticId to assign new UUIDs
// It's a negative value to avoid collisions with any existing data
window.syntheticId = -1;
window.getSyntheticId = () => --window.syntheticId;

ReactDOM.render(
	<React.StrictMode>
		<ErrorBoundary>
			{/* Inject Emotion before custom styles - so we don't override MUI styles with !important */}
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={soomoMuiTheme}>
					<ThemeProvider theme={soomoTheme}>
						<Provider store={store}>
							<ReduxQueryProvider queriesSelector={getQueries}>
								<Router history={history}>
									<Routing />
								</Router>
							</ReduxQueryProvider>
						</Provider>
					</ThemeProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</ErrorBoundary>
	</React.StrictMode>,
	document.getElementById('app')
);

// redux-location-state location tracker
listenForHistoryChange(store, history);
